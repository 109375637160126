import useNetworkStatus from "hooks/network/useNetworkStatus";

const NetworkStatus = () => {
  const { isOnline } = useNetworkStatus();
  return (
    <>
      {isOnline === false && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: "red",
            color: "white",
            textAlign: "center",
            padding: "10px",
            zIndex: 1000,
          }}
        >
          {isOnline === false ? "You are offline" : "Your internet is slow"}
        </div>
      )}
    </>
  );
};

export default NetworkStatus;
