interface ContentLoaderProps {
  withProfile: boolean;
  quantity?: number;
}

const ContentLoader = ({ withProfile, quantity = 1 }: ContentLoaderProps) => {
  const loaders = Array.from({ length: quantity }, (_, index) => (
    <div key={index} className="my-4 w-full">
      <div className="animate-pulse flex space-x-4">
        {withProfile && (
          <div className="rounded-full bg-gray-300 h-10 w-10"></div>
        )}

        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 bg-gray-300 rounded"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              <div className="h-2 bg-gray-300 rounded col-span-1"></div>
            </div>
            <div className="h-2 bg-gray-300 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  ));

  return <div className="flex flex-col gap-4 w-full">{loaders}</div>;
};

export default ContentLoader;
