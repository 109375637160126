import ProfileHeader from "./ProfileHeader";
import ProfilePictureSelector from "./ProfilePictureSelector";
import Header from "components/Header/Header";
import EmptySpace from "components/utility/EmptySpace";

import ProfileForm from "./ProfileForm";
import Tab from "components/utility/Tab/Tab";
import TabItem from "components/utility/Tab/TabItem";
import { useLocation } from "react-router-dom";

import { useAuthContext } from "hooks/useAuthContext";
import { useEffect } from "react";
import NotificationsComponent from "./Notifications/NotificationsComponent";

/**
 * A page to update profile information.
 * Allows update of display name, first name, last name, email,
 * gender, birthday. Contains a link to a page to rest password.
 *
 * @returns ProfileSettings page.
 */
const ProfileSettings = () => {
  const location = useLocation();
  const { profile } = useAuthContext();

  useEffect(() => {
    if (!profile) return;
  }, [profile]);
  return (
    <div>
      <Header />

      <ProfileHeader />
      <EmptySpace />
      <div className="max-w-3xl mx-auto px-4">
        <ProfilePictureSelector />
      </div>
      <div className="mt-8 max-w-7xl flex justify-start items-center mx-auto px-4">
        <Tab>
          <TabItem title="Profile Settings">
            <ProfileForm />
          </TabItem>

          <TabItem
            active={location.pathname.includes("/notifications")}
            title="Notifications"
          >
            {profile && <NotificationsComponent profile={profile} />}
          </TabItem>
        </Tab>
      </div>

      <EmptySpace />
    </div>
  );
};

export default ProfileSettings;
