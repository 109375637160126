import { Profile } from "interface/ProfileInterface";
import { updateLogStatus, UserLog } from "models/usersLog";
import { useEffect, useState } from "react";
import { BiCalendar } from "react-icons/bi";
import { IoMdNotifications } from "react-icons/io";

type NotificationItemProps = {
  notification: UserLog;
  profile: Profile;
};

const NotificationItem = ({ notification, profile }: NotificationItemProps) => {
  const [read, setRead] = useState(false);
  const readHandler = async () => {
    try {
      if (notification.id) {
        await updateLogStatus(profile.uid, notification.id, "read");
        setRead(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (notification && notification.status === "read") {
      setRead(true);
    } else {
      setRead(false);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`${
        !read ? "bg-gray-200/50 cursor-pointer" : "bg-white ml-2"
      }  p-5 border-l-4 border-[#f9bb62] my-4 relative`}
      onClick={notification.status === "unread" ? readHandler : () => {}}
    >
      <div className="flex justify-center items-center gap-4">
        <IoMdNotifications color="#f9bb62" size={32} className="shrink-0" />
        <p className="text-standard py-5">{notification.log}</p>
        <p className="text-standard absolute bottom-2 right-2 text-sm flex gap-1 justify-center items-center">
          <BiCalendar />
          {notification.timestamp.toDate().toLocaleDateString("en-US", {
            timeZone: "Asia/Manila",
            month: "long",
            day: "numeric",
            year: "numeric",
          })}
          {" - "}
          {notification.timestamp.toDate().toLocaleTimeString("en-US", {
            timeZone: "Asia/Manila",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </p>
      </div>
    </div>
  );
};

export default NotificationItem;
