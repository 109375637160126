import Modal from "components/utility/Modal";
import { useAuthContext } from "hooks/useAuthContext";
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from "react";

import classes from "./ProfileSettings.module.css";
import Button from "components/utility/Button";

import { useUploadImage } from "hooks/useUploadImage";
import ImagePicker from "components/utility/Forms/ImagePicker/ImagePicker";
import useUser from "hooks/useUser";
import EmptySpace from "components/utility/EmptySpace";
import DebouncedButton from "components/utility/Buttons/DebouncedButton";
import ProfilePicture from "components/ProfilePicture/ProfilePicture";
import NoteToTerms from "components/Login/NoteToTerms";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

/**
 * Modal to handle upload of profile picture.
 *
 * @param isModalOpen State variable indicating if the modal should be open.
 * @param setIsModalOpen State setter function to change the open state of the modal.
 * @returns ProfilePictureUploadModal component.
 */
const ProfilePictureUploadModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
}) => {
  const { user } = useAuthContext();
  const { profile, setProfile } = useUser();
  const { uploadImage, getImageLink } = useUploadImage(
    `/profile_pictures/${user?.uid}`
  );
  const [file, setFile] = useState<File | null>(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (user && e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    if (file) {
      uploadImage(file);
    }
    setIsUploaded(true);
  };

  const handleRefresh = async () => {
    if (profile) {
      return getImageLink()
        .then((newLink) =>
          setProfile({
            ...profile,
            photoURL: newLink,
          })
        )
        .catch((e) => {
          if (e.code === "storage/object-not-found") {
            return;
          }
          console.log(e.code);
        });
    }
  };

  const handleClose = () => {
    setFile(null);
    setIsUploaded(false);
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onClose={handleClose}
    >
      <div className={classes.modal}>
        {isUploaded ? (
          <>
            Profile picture may take a few seconds to update
            <EmptySpace />
            <ProfilePicture profile={profile} />
            <EmptySpace />
            <DebouncedButton
              text="Refresh profile picture"
              onClick={handleRefresh}
            />
          </>
        ) : (
          <>
            <ImagePicker onChange={handleSelectImage} />
            {file && <Button onClick={handleSubmit} text="Done" />}
            <br />
            <NoteToTerms />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ProfilePictureUploadModal;
