import FirstNameAvatar from "components/utility/FirstNameAvatar";
import useGetParticipants from "hooks/useGetParticipants";
import useParticipants from "hooks/useParticipants";
import usePromise from "hooks/utility/usePromise";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";
import { Participant } from "interface/ParticipantInterface";
import { coHostRole, defaultRole, Role } from "models/organisationRole";

import classes from "pages/AllCircles/Admin/Admin.module.css";
import { useState } from "react";

type ParticipantComponentProps = {
  participant: Participant;
  isActive: boolean;
  onToggle: (userId: string) => void;
  growthCircleSession: GrowthCircleSession;
};

const ParticipantComponent = ({
  participant,
  isActive,
  onToggle,
  growthCircleSession,
}: ParticipantComponentProps) => {
  const { getParticipantColourIndex } = useGetParticipants();
  const { updateSessionRole, addParticipantEventLog } = useParticipants();
  const [action, setAction] = useState<string>("");
  const { isLoading, resolve } = usePromise();

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const logRoleUpdate = async (
    participantId: string,
    sessionId: string,
    role: Role
  ) => {
    const time = formatDate(new Date());
    await addParticipantEventLog(
      participantId,
      sessionId,
      `User role was updated to ${
        role.name === "" ? "participant" : role.name
      } at ${time}`
    );
  };

  const updateRoleEventHandler = async (type: string) => {
    try {
      const role = type === "co-host" ? coHostRole : defaultRole;
      await resolve(() => updateSessionRole(participant.id, role)).then(() =>
        onToggle("")
      );
      await logRoleUpdate(participant.id, growthCircleSession.id, role);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveHandler = async () => {
    try {
      switch (action) {
        case "co-host":
        case "participant":
          await updateRoleEventHandler(action);
          break;
        default:
          console.warn("Unknown action type");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={`${
        growthCircleSession.facilOwner !== participant.userId
          ? "cursor-pointer"
          : ""
      }`}
    >
      <FirstNameAvatar
        size={50}
        name={participant.userName}
        participant={participant}
        styleTriggered={true}
        participantNumber={getParticipantColourIndex(participant.userId)}
        onClick={() => onToggle(participant.userId)}
      />

      {growthCircleSession.facilOwner !== participant.userId && isActive && (
        <div
          className={`flex flex-col justify-end items-start gap-2 mb-[150px] my-2  `}
        >
          <div className="bg-default z-1000 p-2 flex flex-col gap-4 border justify-end items-start ">
            <p className="text-standard font-semibold">
              {participant.userName}
            </p>
            <select
              onChange={(e) => setAction(e.target.value)}
              autoFocus
              className="focus:border-[var(--main-colour)] w-full"
            >
              <option value="">Select Action</option>
              {participant.sessionRole === "" && (
                <option value="co-host">Assign as Co-host</option>
              )}
              {participant.sessionRole !== "" && (
                <option value="participant">Assign as Participant</option>
              )}
            </select>
            <div className={`${classes.container}  flex gap-2`}>
              <button
                disabled={isLoading || action === ""}
                className={classes.button}
                onClick={onSaveHandler}
              >
                {isLoading ? "Saving..." : "Save"}
              </button>
              <button
                disabled={isLoading}
                className={classes.button}
                onClick={() => onToggle("")}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipantComponent;
