import { Profile } from "interface/ProfileInterface";
import { getUserLogs, UserLog } from "models/usersLog";
import { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
import usePromise from "hooks/utility/usePromise";
import ContentLoader from "components/Loaders/ContentLoader";
import { orderBy } from "firebase/firestore";

type NotificationsComponentProps = {
  profile: Profile;
};

const NotificationsComponent = ({ profile }: NotificationsComponentProps) => {
  const [notifications, setNotifications] = useState<UserLog[]>([]);
  const { isLoading, resolve } = usePromise();
  const getAllLogs = async () => {
    try {
      const logs = await getUserLogs(profile.uid, orderBy("timestamp", "desc"));
      if (logs && logs.length > 0) {
        setNotifications(logs);
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    resolve(() => getAllLogs());
    // eslint-disable-next-line
  }, [profile]);

  return (
    <div>
      {!isLoading ? (
        notifications.map((notification, index) => (
          <NotificationItem
            profile={profile}
            notification={notification}
            key={index}
          />
        ))
      ) : (
        <ContentLoader withProfile={false} />
      )}
      {!isLoading && notifications.length === 0 && (
        <p className="text-standard py-10 px-5">No notification available.</p>
      )}
    </div>
  );
};

export default NotificationsComponent;
