import { Participant } from "interface/ParticipantInterface";
import { R_CO_HOST, R_HOST } from "models/organisationRole";
import { useCallback, useState } from "react";
import ParticipantComponent from "./ParticipantComponent";
import { GrowthCircleSession } from "interface/GrowthCircleSessionInterface";

type ParticipantListViewProps = {
  participants: Participant[];
  growthCircleSession: GrowthCircleSession;
};

const ParticipantListView = ({
  participants,
  growthCircleSession,
}: ParticipantListViewProps) => {
  const [activeParticipantId, setActiveParticipantId] = useState<string | null>(
    null
  );

  const toggleParticipant = (userId: string) => {
    setActiveParticipantId((prevUserId) =>
      prevUserId === userId ? null : userId
    );
  };

  const checkIsHostOrCoHost = useCallback(
    (participant: Participant | undefined) => {
      if (!participant) {
        return false;
      }
      const roleName = participant.sessionRole;
      return roleName === R_HOST || roleName === R_CO_HOST;
    },
    []
  );

  return (
    <div className="flex-col gap-4 justify-start items-start w-full">
      <h2
        className="text-left my-4 font-semibold"
        style={{ color: "var(--main-colour)" }}
      >
        Host / Co-Host
      </h2>
      <div className="flex flex-wrap gap-4">
        {participants
          .filter((parti) => checkIsHostOrCoHost(parti))
          .map((participant, index) => (
            <div key={index} className="rounded-full w-[50px] h-[50px]">
              <ParticipantComponent
                growthCircleSession={growthCircleSession}
                participant={participant}
                isActive={activeParticipantId === participant.userId}
                onToggle={() => toggleParticipant(participant.userId)}
              />
            </div>
          ))}
      </div>

      <h2
        className="text-left my-4 font-semibold"
        style={{ color: "var(--main-colour)" }}
      >
        Participants
      </h2>
      <div className="flex flex-wrap gap-4">
        {participants
          .filter((parti) => !checkIsHostOrCoHost(parti))
          .map((participant, index) => (
            <div key={index} className="rounded-full w-[50px] h-[50px]">
              <ParticipantComponent
                growthCircleSession={growthCircleSession}
                participant={participant}
                isActive={activeParticipantId === participant.userId}
                onToggle={() => toggleParticipant(participant.userId)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ParticipantListView;
