import { QueryConstraint, UpdateData } from "firebase/firestore";
import firebase from "firebase/compat/app";
import getModelOperations from "utility/model";
import { timestamp } from "../firebase/config";

const FIRESTORE_USER_PATH = "users";
const FIRESTORE_SUB_PATH = "logs";
type LOG_TYPES = "ROLE" | "ORGANISATION" | "DEFAULT";
type LOG_STATUS = "read" | "unread";

export interface UserLog {
  id?: string;
  timestamp: firebase.firestore.Timestamp;
  type: LOG_TYPES;
  status: LOG_STATUS;
  log: string;
}

export const defaultLog: UserLog = {
  timestamp: timestamp.fromDate(new Date()),
  type: "DEFAULT",
  status: "unread",
  log: "",
};

const ops = getModelOperations(defaultLog);

async function _addLog(userId: string, log: UserLog) {
  const path = `${FIRESTORE_USER_PATH}/${userId}/${FIRESTORE_SUB_PATH}`;

  try {
    const docRef = await firebase.firestore().collection(path).add(log);

    const addedLogSnapshot = await docRef.get();
    const addedLog = {
      id: addedLogSnapshot.id,
      ...addedLogSnapshot.data(),
    } as UserLog & { id: string };

    return addedLog;
  } catch (error) {
    console.error("Error adding log:", error);
    throw error;
  }
}

function _updateLog(
  userId: string,
  logId: string,
  logUpdates: UpdateData<UserLog>
) {
  // Ensure logUpdates does not include the 'id' field
  const { ...updates } = logUpdates; // Destructure id from updates

  const path = `${FIRESTORE_USER_PATH}/${userId}/${FIRESTORE_SUB_PATH}/${logId}`;
  return ops.updateModel(path, updates);
}

function _getLog(userId: string, logId: string) {
  const path = `${FIRESTORE_USER_PATH}/${userId}/${FIRESTORE_SUB_PATH}/${logId}`;
  return ops.getModel(path);
}

async function _getLogs(
  userId: string,
  ...queryConstraints: QueryConstraint[]
) {
  const path = `${FIRESTORE_USER_PATH}/${userId}/${FIRESTORE_SUB_PATH}`;
  const result = await ops.getModels(path, ...queryConstraints);
  return result;
}

async function addLog(userId: string, log: UserLog) {
  return _addLog(userId, log);
}

export async function addRoleLog(userId: string, log: string) {
  const newLog: UserLog = {
    timestamp: timestamp.fromDate(new Date()),
    type: "ROLE",
    status: "unread",
    log,
  };

  await addLog(userId, newLog);
}

export async function updateLogType(
  userId: string,
  logId: string,
  type: LOG_TYPES
) {
  return _updateLog(userId, logId, { type });
}

export async function updateLogStatus(
  userId: string,
  logId: string,
  status: LOG_STATUS
) {
  return _updateLog(userId, logId, { status });
}

export const getUserLogById = _getLog;
export const getUserLogs = _getLogs;
