import usePromise from "hooks/utility/usePromise";
import { getUserLogs, UserLog } from "models/usersLog";
import { useEffect, useState } from "react";
import { IoMdNotifications, IoMdSettings } from "react-icons/io";
import { Link } from "react-router-dom";
import { orderBy } from "firebase/firestore";
import { Profile } from "interface/ProfileInterface";

type NavigationIconProps = {
  profile: Profile;
};

const NavigationIcon = ({ profile }: NavigationIconProps) => {
  const [notifications, setNotifications] = useState<UserLog[]>([]);
  const { isLoading, resolve } = usePromise();
  const getAllLogs = async () => {
    try {
      const logs = await getUserLogs(profile.uid, orderBy("timestamp", "desc"));
      if (logs && logs.length > 0) {
        const _unreadLog = logs.filter((log) => log.status === "unread");
        setNotifications(_unreadLog);
      } else {
        setNotifications([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    resolve(() => getAllLogs());
    // eslint-disable-next-line
  }, [profile]);
  return (
    <div className="text-standard flex gap-2 md:py-0 py-2  mx-auto md:mx-0 w-56">
      <Link to={`/my-account/notifications`}>
        <div className="relative cursor-pointer text-standard">
          {!isLoading && notifications.length > 0 && (
            <span className="absolute -top-2 -right-2 text-red-200 text-center text-sm rounded-full bg-red-700 w-6 h-6 ">
              {notifications.length}
            </span>
          )}
          <IoMdNotifications size={"1.2em"} />
        </div>
      </Link>
      <Link to={`/my-account`}>
        <div className="relative cursor-pointer text-standard">
          <IoMdSettings size={"1.2em"} />
        </div>
      </Link>
    </div>
  );
};

export default NavigationIcon;
